window.$ = window.jQuery = require('jquery/dist/jquery.min');
require('bootstrap/dist/js/bootstrap.min');
require('popper.js/dist/popper.min');
require('uikit/dist/js/uikit.min');
require('uikit/dist/js/uikit-core.min');
require('uikit/dist/js/uikit-icons.min');
import "inputmask";

//Inputmask para todo os input // npm install jquery.inputmask
let elementos  = document.getElementsByTagName('input');
if(elementos ) Inputmask().mask(elementos);

// Photoswipe
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';


import 'flowbite';
//------------Animao Home-------------
window.AOS = require('AOS');
AOS.init();

// Botao toggle
$('.btn-toggle').click(function () {
    $(this).toggleClass('active');
});

// Paginacao
const lightbox_paginacao = new PhotoSwipeLightbox({
    gallery: '#gallery--paginacao',
    children: 'a',
    showHideAnimationType: 'fade',
    pswpModule: () => import('photoswipe')
});
lightbox_paginacao.init();

// Seleciona os elementos da classe
const sections = document.getElementsByClassName('none-1s');

// Remove a classe depois de 1 segundos
setTimeout(() => {

    for(let i = 0; i < sections.length; i++) {
        sections[i].classList.remove('d-none');
    }
}, 1000);

$('#sendWpp').on('click', function (){ //botão enviando
    document.getElementById('sendWpp').classList.add('d-none')
    document.getElementById('charging').classList.remove('d-none')

})

//focus em div contato após solicitação em wpp

// const submitButton = document.getElementById('send-wpp');
let element = document.getElementById("divfocus");
$( "#sendWpp" ).on( "click", function() {
    element.scrollIntoView();
} );


// ---------------------------------------------- modal sobre villagres ---------------------------

let $videoSrc;
$('.video-play').click(function() {
    $videoSrc = $(this).data( "src" );
});

// Quando o modal é aberto, reproduz automaticamente
$('#sobreSoloModal').on('shown.bs.modal', function (e) {

// Defina o vídeo src para reprodução automática e não para mostrar o vídeo relacionado.
    $("#videosobreSolo").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
})

// Pare de jogar o vídeo do youtube quando eu fecho o modal
$('#sobreSoloModal').on('hide.bs.modal', function (e) {
    // vídeo para quando sai do modal
    $("#videosobreSolo").attr('src',$videoSrc);
})




